<template>
  <el-container class="layout">
    <el-header class="el-header_scoped">
      <Head :notReadCount="notReadCount" :isShowNews="isShowNews" v-on:callBackNews="callBackNews"></Head>
    </el-header>
    <el-container class="mainLayout">
      <transition name="el-zoom-in-top">
        <el-aside width="240px" class="el-aside_scoped">
          <MenuBar></MenuBar>
        </el-aside>
      </transition>
      <el-main class="el-main_scoped">
        <router-view></router-view>
        
        <div class="newsCards" v-if="isShowNews">
          <div class="newsCards_title">
            <span>最新消息</span>
            <i class="el-icon-error" @click="isShowNews=false"></i>
          </div>
          <div class="newsCard_content">
            <div
              class="newsCard_content_card"
              v-for="(item,index) in notReadList"
              :key="index"
              v-show="index<10"
            >
              <div class="newsCard_content_card_info">
                <div class="iconfont icon-bell" style="margin-top:5px"></div>
                <div>
                  <p style="margin-left:5px">{{item.title}}</p>
                  <p style="margin-left:5px">{{item.createtime}}</p>
                  <p>{{item.content}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="newsCard_bottom">
            <el-button
              type="primary"
              size="mini"
              style="width:100px"
              @click="onSubmitReadAllNes"
            >全部已读</el-button>
            <el-button
              type="primary"
              plain
              size="mini"
              style="width:100px"
              @click="dialogNews=true"
            >查看全部</el-button>
          </div>
        </div>

        <el-dialog
          :visible.sync="dialogNews"
          :close-on-click-modal="false"
          width="35%"
          id="newsDialog"
        >
          <span slot="title">消息中心</span>
          <div>
            <el-tabs v-model="activeName" type="border-card" tab-position="top" stretch>
              <el-tab-pane name="off">
                <span slot="label">
                  未读消息
                  <el-tooltip effect="dark" content="全部已读" placement="top">
                    <i class="el-icon-view" @click="onSubmitReadAllNes"></i>
                  </el-tooltip>
                </span>
                <div
                  v-for="item in notReadList.slice((notCurrentPage-1)*notPageSize,notCurrentPage*notPageSize)"
                  :key="item.id"
                >
                  <div class="dialogNews_view" @click="onSubmitReadNews(item)">
                    <div class="dialogNews_view_left">
                      <i class="iconfont icon-bell" style="font-size:40px"></i>
                      <div>
                        <p style="font-weight:700;font-size:14px">{{item.title}}</p>
                        <p style="font-size:12px;margin-top:5px">{{item.content}}</p>
                      </div>
                    </div>
                    <div class="dialogNews_view_right">
                      <p>{{item.createtime}}</p>
                      <el-button
                        type="text"
                        size="mini"
                        style="margin-top:5px"
                        @click.stop="onSubmitDelNews(item)"
                      >删除</el-button>
                    </div>
                  </div>
                </div>

                <div class="page_right">
                  <el-pagination
                    background
                    layout="total,prev, pager, next ,jumper"
                    :total="notReadList.length"
                    :page-size="notPageSize"
                    :current-page="notCurrentPage"
                    @current-change="handleNotCurrentChange"
                  ></el-pagination>
                </div>
              </el-tab-pane>
              <el-tab-pane label="已读消息" name="on">
                <div
                  v-for="item in readList.slice((readCurrentPage-1)*readPageSize,readCurrentPage*readPageSize)"
                  :key="item.id"
                >
                  <div class="dialogNews_view">
                    <div class="dialogNews_view_left">
                      <i class="iconfont icon-bell" style="font-size:40px;color:#909399"></i>
                      <div>
                        <p style="font-weight:700;font-size:14px">{{item.title}}</p>
                        <p style="font-size:12px;margin-top:5px">{{item.content}}</p>
                      </div>
                    </div>
                    <div class="dialogNews_view_right">
                      <p>{{item.createtime}}</p>
                      <el-button
                        type="text"
                        size="mini"
                        style="margin-top:5px"
                        @click.stop="onSubmitDelNews(item)"
                      >删除</el-button>
                    </div>
                  </div>
                </div>

                <div class="page_right">
                  <el-pagination
                    background
                    layout="total,prev, pager, next ,jumper"
                    :total="notReadList.length"
                    :page-size="readPageSize"
                    :current-page="readCurrentPage"
                    @current-change="handleCurrentChange"
                  ></el-pagination>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import MenuBar from "./menu/MenuBar.vue";
import Head from "./menu/Head.vue";
import {
  getNewsApi,
  delNewsApi,
  readNewsApi,
  readAllNewsApi
} from "../../api/data";
export default {
  components: {
    MenuBar,
    Head
  },
  data() {
    return {
      readList: [],
      notReadList: [],
      notReadCount: 0,
      isShowNews: false,
      dialogNews: false,
      notPageSize: 10,
      notCurrentPage: 1,
      readPageSize: 10,
      readCurrentPage: 1,
      activeName: "off"
    };
  },
  created() {
    this.initNews();
  },
  methods: {
    //初始化消息
    initNews() {
      //未读消息
      getNewsApi({ state: "off" }).then(res => {
        this.notReadList = res.data;
        this.notReadCount = res.data.length;
      });
      //已读消息
      getNewsApi({ state: "on" }).then(res => {
        this.readList = res.data;
      });
    },
    //消息中心点击分页
    handleNewsCurrentChange(currentPage) {
      this.newsPage = currentPage;
    },
    //全部已读
    onSubmitReadAllNes() {
      this.$confirm("确定要全部已读吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        readAllNewsApi().then(res => {
          if (res) {
            this.initNews();
          }
        });
      });
    },
    //单条已读
    onSubmitReadNews(obj) {
      readNewsApi({ id: obj.id }).then(res => {
        if (res) {
          this.initNews();
        }
      });
    },
    //删除消息
    onSubmitDelNews(obj) {
      this.$confirm("此操作将永久删除该消息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delNewsApi({ id: obj.id }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initNews();
          }
        });
      });
    },
    //消息窗口事件
    callBackNews(val) {
      this.isShowNews = val;
    },
    //选择第几页
    handleNotCurrentChange(currentPage) {
      this.notCurrentPage = currentPage;
    },
    //选择第几页
    handleCurrentChange(currentPage) {
      this.readCurrentPage = currentPage;
    }
  }
};
</script>
<style lang='scss'>
.layout {
  // width: 100%;
  // height: 100vh;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.el-header_scoped {
  background: transparent;
  position: relative;
  width: 100%;
}
.el-aside_scoped {
  display: block;
  position: absolute;
  left: 0;
  top: 60px;
  bottom: 0;
}
.el-main_scoped {
  background-color: #efefef;
  position: absolute;
  left: 220px;
  right: 0;
  top: 60px;
  bottom: 0;
  overflow-y: auto;
}

.card_header_flex {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
}
.page_right {
  margin: 10px;
  text-align: right;
}

.newsCards {
  background: #ffffff;
  height: 350px;
  width: 300px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  animation: mamore 1s 1;
  border-radius: 6px;
  z-index: 10;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .newsCards_title {
    text-align: center;
    padding: 10px;
    background: rgba($color: #025cdf, $alpha: 1);
    font-size: 16px;
    border-radius: 6px 6px 0 0;
    color: #ffffff;
    i {
      float: right;
      cursor: pointer;
    }
    i:hover {
      color: rgba($color: #3d8cff, $alpha: 0.8);
    }
  }
  .newsCard_content {
    height: 270px;
    overflow-y: auto;
    .newsCard_content_card {
      border-bottom: 1px solid #eee;
      padding: 10px 20px;
      .newsCard_content_card_info {
        display: flex;
        cursor: pointer;
        p {
          margin-top: 5px;
        }
      }
    }
    .newsCard_content_card:hover {
      background: #eee;
    }
  }
  .newsCard_bottom {
    padding: 10px 30px;
    // border-top: 1px solid #eee;
    display: flex;
    justify-content: space-between;
  }
}

@keyframes mamore {
  from {
    bottom: -375px;
  }
  to {
    bottom: 0px;
  }
}

.dialogNews_view {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 10px 20px;
  cursor: pointer;
  .dialogNews_view_left {
    display: flex;
    align-items: center;
    width: 70%;
  }
  .dialogNews_view_right {
    width: 30%;
    text-align: right;
  }
}
.dialogNews_view:hover {
  background: #efefef;
}
#newsDialog .el-dialog__body {
  padding: 0px !important;
}
</style>