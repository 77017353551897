<template>
  <div class="menuBar">
    <div class="menuLeft">
      <div class="menuItems">
        <div
          :class="item.menuId == menuSeleted? 'menuItem__view_Active': 'menuItem__view'"
          v-for="(item,index) in list"
          :key="index"
          @click.stop="selectedMenuBar(index,item.menuId)"
        >
          <div>
            <div :class="item.icon" class="menuItem__icon"></div>
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="menuRight">
      <div v-for="(items,index) in subMenuList" :key="index">
        <div class="menuTitle">{{items.name}}</div>
        <div
          class="right__view"
          v-for="obj in items.subMenu"
          :key="obj.menuId"
          @click.stop="selecSubMenu(obj.path)"
        >
          <div :class="obj.path == subMenuSeleted? 'right__Item_Active': 'right__Item'">
            <div :class="obj.icon" class="right__Item__icon"></div>
            <div>{{ obj.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getHomeMenuApi } from "../../../api/data";
import Aes from "../../../utils/aes";
export default {
  data() {
    return {
      list: [],
      subMenuList: []
    };
  },
  computed: {
    //一级菜单选中
    menuSeleted() {
      return this.$store.state.tab.isMenuSelected;
    },
    //三级菜单选中
    subMenuSeleted() {
      return this.$store.state.tab.isSubMenuSelected;
    },
    //场所信息
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    // 左侧菜单
    MenuList() {
      return this.$store.state.tab.isMenuList;
    },
    linkUrl() {
      return this.$store.state.tab.isLinkUrl;
    }
  },
  watch: {
    menuSeleted() {
      this.initLoadMenu();
    },
    linkUrl(newVal) {
      if (newVal != "") this.toLinkUrl(newVal);
    }
  },
  created() {
    this.initLoadMenu();
  },
  methods: {
    //初始化左侧菜单
    initLoadMenu() {
      getHomeMenuApi({
        groupId: this.placesInfo.groupId
      }).then(res => {
        this.list = res.data;
        this.$store.commit("setMenuList", this.list);
        //排序左侧菜单
        this.list = this.sortArray(this.list, "sort");
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].subMenu = this.sortArray(this.list[i].subMenu, "sort");
          for (let j = 0; j < this.list[i].subMenu.length; j++) {
            this.list[i].subMenu[j].subMenu = this.sortArray(
              this.list[i].subMenu[j].subMenu,
              "sort"
            );
          }
        }

        //刷新或需要显示首页菜单时
        if (this.menuSeleted == "") {
          //显示二三级菜单集合
          this.subMenuList = this.list[0].subMenu;
          //选中一级菜单
          this.$store.commit("setMenuSelected", this.list[0].menuId);
          //选中三级菜单
          this.$store.commit(
            "setSubMenuSelected",
            this.list[0].subMenu[0].subMenu[0].path
          );
          this.$router.push(this.list[0].subMenu[0].subMenu[0].path);
        } else {
          for (let i = 0; i < this.list.length; i++) {
            for (let j = 0; j < this.list[i].subMenu.length; j++) {
              for (let k = 0; k < this.list[i].subMenu[j].subMenu.length; k++) {
                if (
                  this.list[i].subMenu[j].subMenu[k].path == this.subMenuSeleted
                ) {
                  //显示二三级菜单集合
                  this.subMenuList = this.list[i].subMenu;
                  this.$router.push(this.list[i].subMenu[j].subMenu[k].path);
                }
              }
            }
          }
        }
      });
    },

    //点击头部选择场所后跳转页面刷新
    toLinkUrl(newVal) {
      for (let i = 0; i < this.list.length; i++) {
        for (let j = 0; j < this.list[i].subMenu.length; j++) {
          for (let k = 0; k < this.list[i].subMenu[j].subMenu.length; k++) {
            if (this.list[i].subMenu[j].subMenu[k].path == newVal) {
              //选中一级菜单
              this.$store.commit("setMenuSelected", this.list[i].menuId);
              //选中三级菜单
              this.$store.commit(
                "setSubMenuSelected",
                this.list[i].subMenu[j].subMenu[k].path
              );
              //清空跳转中间键
              this.$store.commit("setLinkUrl", "");
              //显示二三级菜单集合
              this.subMenuList = this.list[i].subMenu;
              this.$router.push(this.list[i].subMenu[j].subMenu[k].path);
            }
          }
        }
      }
    },

    //点击一级菜单加载二三级
    selectedMenuBar(index, id) {
      this.subMenuList = this.list[index].subMenu;
      this.$store.commit("setMenuSelected", id);
      this.$store.commit(
        "setSubMenuSelected",
        this.subMenuList[0].subMenu[0].path
      );
      this.$router.push(this.subMenuList[0].subMenu[0].path);
    },

    //三级菜单链接跳转
    selecSubMenu(path) {
      this.$store.commit("setSubMenuSelected", path);
      this.$router.push(path);
    },

    //排序
    sortArray(array, key) {
      return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    }
  }
};
</script>
<style lang='scss' scoped>
.menuItem__view_Active {
  background: linear-gradient(#4b90f3, #025cdf);
  height: 70px;
  width: 100%;
  color: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
}
.menuBar {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  overflow: hidden;
  position: relative;
  .menuLeft {
    width: 100px;
    display: flex;
    flex-wrap: wrap;
    color: #000000;
    background-color: #38466f;
    overflow-y: auto;
    .menuItems {
      width: 70px;
      height: 100%;
      .menuItem__view {
        height: 70px;
        width: 100%;
        color: #fff;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        cursor: pointer;
      }
      .menuItem__view:hover {
        color: #b0afbc;
      }
    }
  }
  .menuLeft::-webkit-scrollbar {
    display: none;
  }
  .menuItem__childs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
  .menuItem__icon {
    font-size: 24px;
    margin-bottom: 5px;
  }

  .menuRight {
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: #222439;
    padding-top: 10px;
    padding-left: 10px;
    overflow-y: auto;
  }
  .menuRight::-webkit-scrollbar {
    display: none;
  }
  .menuTitle {
    margin-top: 10px;
    margin-left: 30px;
    font-size: 12px;
    height: 30px;
    color: #b0afbc;
  }

  .right__view {
    display: flex;
    align-items: center;
    height: 50px;
    cursor: pointer;
  }
  .right__Item {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding-left: 20px;
  }
  .right__Item:hover {
    color: #025cdf;
    border-radius: 2px;
    // margin-left: 14px;
  }
  .right__Item_Active {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    // justify-content: center;
    background: linear-gradient(316deg, #e4ecff 0, #fff 100%);
    color: #025cdf;
    // margin-left: 14px;
    border-radius: 6px;
  }
  .right__Item__icon {
    margin-right: 2px;
  }
}
</style>